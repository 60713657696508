import { useContext, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import { Alert, CertificateActiveButton, ComponentLoadingSpinner } from "../../../components";
import { ConfirmModal } from "../../../components/ConfirmModal";
import { KatreSettingsContext } from "../../../contexts/KatreSettingsContext";
import { IContext, IDefaultResponseModel } from "../../../interfaces";
import { IKatreSettings } from "../../../interfaces/KatreSettings"
import katreService from "../../../services/katreService";
import { triggerWootric } from "../../../utils";
import { ResponseTypeEnum } from "../../../utils/constants";
import { ViewAllCompanyCertificatesModal } from "../ViewAllCompanyCertificatesModal";

export const KatreCertificate = ({ data }: { data: IKatreSettings }) => {
    const { t } = useTranslation(["general", "katre"]);
    const { showAlert, refreshData, clearAlert } = useContext(KatreSettingsContext);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const currentCompany = useOutletContext<IContext>();
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [showViewAllModal, setShowViewAllModal] = useState<boolean>(false);

    const handleTestCertificate = () => {
        setShowLoading(true);
        katreService.testCertificate()
            .then((response: IDefaultResponseModel) => {
                showAlert({ message: response.Message, type: response.ResponseType });
                setShowLoading(false);
            })
    }

    const handleDeleteCertificate = () => {
        clearAlert();
        katreService.deleteCertificate()
            .then((response: IDefaultResponseModel) => {
                if (response.ResponseType === ResponseTypeEnum.Success)
                    refreshData();
                else showAlert({ message: response.Message, type: response.ResponseType });
            })
    }


    return (<div>
        <div className="mt-32">
            <h2 className="mt-32 mb-24">{t("IncomesRegisterCertificate", { ns: "katre" })}</h2>
            {
                data.DisplayExpirationDateWarning && <Alert alert={{
                    type: ResponseTypeEnum.Warning,
                    title: t("CertificationExpiration"),
                    sticky: true,
                    message: <p>{t("RenewalNotPossible")} <a href="/settings?replaceKatre=true">{t("PleaseSendRequest", { ns: "katre" })}.</a> </p>
                }} />
            }
            <div className="row">
                <div className="col col-4">
                    <p>{t("SerialNumber")}</p>
                </div>
                <div className="col col-8">
                    <b>{data.SerialNumber}</b>
                </div>
            </div>
            <div className="row mt-24">
                <div className="col col-4">
                    <p>{t("CommonName")}</p>
                </div>
                <div className="col col-8">
                    <b>{data.CommonName}</b>
                </div>
            </div>
            <div className="row mt-24">
                <div className="col col-4">
                    <p>{t("TaxRegistrationNumber")}</p>
                </div>
                <div className="col col-8">
                    <b>{data.TaxNumber}</b>
                </div>
            </div>
            <div className="row mt-24">
                <div className="col col-4">
                    <p>{t("ValidUntil")}</p>
                </div>
                <div className="col col-8" data-testid="expiration-date">
                    <b>{data.ExpirationDate}.</b> {data.DisplayExpirationDateWarning ? t("RenewalNotPossible") : t("CertificateIsRenewedAutomatically")}
                </div>
            </div>
            <div className="d-flex align-items-center mt-24">
                <CertificateActiveButton title={t("CertificateActive")} />
                <div>
                    <Trans
                        i18nKey="CredentialsStatistics"
                        ns={"general"}
                        values={{ companies: data.ActivatedFor, totalCompanies: data.TotalCompanies }}
                        components={{ bold: <strong /> }}
                    />
                </div>
            </div>
        </div>
        <div className="mt-32">
            <h2 className="mt-32">{t("CertificateOptions")}</h2>
            <div className="mt-32 d-flex">
                <button className="btn"
                    data-testid="test-certificate-button"
                    onClick={handleTestCertificate}>{t("TestCertificate")}</button>
                <button className="btn ml-32"
                    data-testid="remove-certificate-button"
                    onClick={() => setShowConfirmModal(true)}>{t("Remove")}</button>
            </div>
        </div>
        <div className="mt-32">
            <h2 className="mt-32">{t("AllCompanies")}</h2>
            <p className="mt-32 mb-0">{t("ViewAllMessage")}</p>
            <button className="btn mt-32"
                data-testid="view-all-button"
                onClick={() => setShowViewAllModal(true)}>{t("ViewAll")}..</button>
        </div>
        {showConfirmModal && <ConfirmModal title={`${t("Remove")} ${t("Settings").toLowerCase()}`}
            description={t("ConfirmRemoveMessage", { companyName: currentCompany.Name, ns: "katre" })}
            onSuccess={handleDeleteCertificate}
            onClose={() => setShowConfirmModal(false)} />}

        {showViewAllModal &&
            <ViewAllCompanyCertificatesModal
                onClose={() => { setShowViewAllModal(false); triggerWootric('settings_modal'); }}
                currentCertificate={data.SerialNumber}
                fetchTableData={katreService.getSettingsForAvailableCompanies}
                copyCertificateForCompanies={katreService.copySettingsForCompanies}
                showAlert={showAlert}
                refreshData={refreshData} />
        }
        {showLoading && <ComponentLoadingSpinner />}
    </div>);
}
import digipoortService from "../../../../services/digipoortService";
import { toLower } from "lodash";
import { useContext, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Alert, CertificateActiveButton, ComponentLoadingSpinner } from "../../../../components";
import { ConfirmModal } from "../../../../components/ConfirmModal";
import { DigipoortSettingsContext } from "../../../../contexts/DigipoortSettingsContext";
import { IDefaultResponseModel, IDigipoortPrivateCertificateProps } from "../../../../interfaces";
import { ResponseTypeEnum } from "../../../../utils/constants";
import { DigipoortPrivateCertificateData } from "./DigipoortPrivateCertificateData";
import { DigipoortPrivateCertificateForm } from "./DigipoortPrivateCertificateForm";
import { ViewAllCompanyCertificatesModal } from "../../ViewAllCompanyCertificatesModal";
import { triggerWootric } from "../../../../utils";

export const DigipoortPrivateCertificate = ({ certificate, totalCompanies, companiesUsingPrivateCertificate }: IDigipoortPrivateCertificateProps) => {
    const { t } = useTranslation(["general", "digipoort"]);
    const { showAlert, refreshData, clearAlert } = useContext(DigipoortSettingsContext);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [showViewAllModal, setShowViewAllModal] = useState<boolean>(false);

    const fileInput = useRef<HTMLInputElement>(null);
    const [fileName, setFileName] = useState<string>("");

    const hasPrivateCertificate = certificate.CommonName !== null && certificate.CommonName !== "";

    const disableUpload = certificate.Deactivated;
    const disableRemoveAndViewAll = certificate.Deactivated || !hasPrivateCertificate;

    const handleConfirmRemove = () => {
        setShowLoading(true);
        setShowConfirmModal(false);
        digipoortService.deletePrivateCertificate()
            .then((data: IDefaultResponseModel) => {
                setShowLoading(false);
                showAlert({ message: data.Message, type: data.ResponseType, title: data.Title });
                refreshData();
            })
    }

    return (
        <>
            {showLoading && <ComponentLoadingSpinner />}
            <div className={`${showLoading ? "d-none": "d-block"}`}>
                <p>{t("DigipoortVismaCertificate", { ns: "digipoort" })}</p>
                {certificate.Deactivated && <Alert alert={{ sticky: true, type: ResponseTypeEnum.Danger, message: t("PrivateCertificateWarning", { ns: "digipoort" }) }} />}
                <DigipoortPrivateCertificateData {...certificate} />
                {hasPrivateCertificate && !certificate.Deactivated &&
                    <div className="d-flex align-items-center mt-32">
                        <CertificateActiveButton title={t("CertificateActive")} />
                        <div data-testid="credentials-statistics">
                            <Trans
                                i18nKey="CredentialsStatistics"
                                ns={"general"}
                                values={{ companies: companiesUsingPrivateCertificate, totalCompanies: totalCompanies }}
                                components={{ bold: <strong /> }}
                            />
                        </div>
                    </div>
                }
                <h2 className="mt-32 mb-32">{t("CertificateOptions")}</h2>
                <div className="d-flex">
                    <div>
                        <button className="btn"
                            data-testid="uplad-button"
                            onClick={() => fileInput.current?.click()}
                            disabled={disableUpload}>{t("Upload")}</button>
                        {fileName && <span className="d-block" data-testid="file-name">{fileName}</span>}
                    </div>
                    <button className="btn ml-32"
                        data-testid="remove-button"
                        onClick={() => setShowConfirmModal(true)}
                        disabled={disableRemoveAndViewAll}>{t("Remove")}</button>
                    <button className="btn ml-32"
                        data-testid="view-all-button"
                        disabled={disableRemoveAndViewAll}
                        onClick={() => setShowViewAllModal(true)}>{t("ViewAll")}..</button>
                </div>
                <DigipoortPrivateCertificateForm
                    fileInput={fileInput}
                    setFileName={setFileName}
                    setShowLoading={setShowLoading}
                    certificate={certificate}
                    hasPrivateCertificate={hasPrivateCertificate} />
            </div>

            {showConfirmModal &&
                <ConfirmModal title={`${t("Remove")} ${toLower(t("Certificate"))}`}
                    description={t("RemovePrivateCertificateMessage", { ns: "digipoort" })}
                    onSuccess={handleConfirmRemove}
                    onClose={() => setShowConfirmModal(false)} />
            }

            {showViewAllModal &&
                <ViewAllCompanyCertificatesModal
                    onClose={() => { setShowViewAllModal(false); triggerWootric('settings_modal'); }}
                    currentCertificate={t("Private")}
                    fetchTableData={digipoortService.getSettingsForAvailableCompanies}
                    copyCertificateForCompanies={digipoortService.copySettingsForCompanies}
                    showAlert={showAlert}
                    refreshData={refreshData} />
            }
        </>
    )
}
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { SignInToIdPortenResponseModel, IAltinnIdPortenAuthenticationModel } from "../../../interfaces";
import { ResponseTypeEnum } from "../../../utils/constants";
import altinnIdPortenService from "../../../services/altinnIdPortenService";
import { ComponentLoadingSpinner } from "../../../components";
import { ReportExpandedRowContext } from "../../../contexts/ReportExpandedRowContext";

export const AltinnIdPorten = ({ reportId }: { reportId: string }) => {
    const [authenticationModel, setAuthenticationModel] = useState<IAltinnIdPortenAuthenticationModel>({} as IAltinnIdPortenAuthenticationModel);
    const { t } = useTranslation(["altinn", "general"]);
    const { setSendButtonProps } = useContext(ReportExpandedRowContext);

    useEffect(() => {
        getAuthenticationModel();
    }, [])

    const getAuthenticationModel = () => {
        altinnIdPortenService
            .getAuthenticationModel(reportId)
            .then((data: IAltinnIdPortenAuthenticationModel) => {
                setAuthenticationModel(data);
            })
    }

    useEffect(() => {
        if (Object.keys(authenticationModel).length > 0)
            setSendButtonProps({
                text: authenticationModel.AuthenticateActionLabel,
                disabled: authenticationModel.SigninRequired || authenticationModel.IsSending,
                getAuthenticationData: getAuthenticationData,
                refreshAuthenticationComponent: getAuthenticationModel
            })
    }, [authenticationModel])

    const getAuthenticationData = () => {
        var authModel = {
            UserId: authenticationModel.UserId
        }

        return authModel;
    }

    const handleSignIn = () => {
        if (authenticationModel)
            altinnIdPortenService
                .signInToIdPorten(`/report/${reportId}/details${window.location.search}`)
                .then((data: SignInToIdPortenResponseModel) => {
                    if (data.ResponseType === ResponseTypeEnum.Success)
                        window.open(data.Url, "_top");
                })
    }

    return (<>
        {authenticationModel ?
            <>
                {authenticationModel.SigninRequired &&
                    <div data-testid="sign-in-required">
                        <p>{authenticationModel.SigninExplanation}</p>
                        <div className="ar-bankid"></div>
                        <div className="ar-minid"></div>
                        <button className="btn btn-primary d-block mt-32"
                            data-testid="sign-in-button"
                            onClick={handleSignIn}
                            title={t("IdPortenSigninTooltip")}>{t("IdPortenSignin")}</button>
                    </div>
                }

                {!authenticationModel.SigninRequired &&
                    <div data-testid="logout-info">
                        <p className="mb-16">{authenticationModel.SigninExplanation}</p>
                        <p className="mb-0"><b>{t("Note", { ns: "general" })} - </b> {t("StaySignedInExplanation")}</p>
                        <a href={authenticationModel.LogoutUrl} target="_top" className="btn mt-32">{t("IdPortenLogout")}</a>
                    </div>
                }
            </>
            :
            <ComponentLoadingSpinner />
        }
    </>)
}
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AltinnUserAuthenticationForm } from "./AltinnUserAuthenticationForm";
import { IAltinnAuthenticationModel, ISystemAuthentication, IUserAuthentication } from "../../interfaces";
import { UserCredentialsFormContext } from "../../contexts/AltinnSettingsContext";
import { AltinnUserAuthenticationContextEnum, DefaultAltinnUserAuthentication } from "../../utils/constants";
import { AltinnCredentialsTimer } from "./AltinnCredentialsTimer";

export const AltinnUserAuthentication = ({ authenticationModel, systemAuthentication, userAuthentication, setUserAuthentication, useNewSettings, ids, source }:
    {
        authenticationModel: IAltinnAuthenticationModel,
        userAuthentication: IUserAuthentication,
        systemAuthentication: ISystemAuthentication,
        useNewSettings: boolean,
        setUserAuthentication: (value: (IUserAuthentication | ((prevUserAuthentication: IUserAuthentication) => IUserAuthentication))) => void,
        ids: string[],
        source: AltinnUserAuthenticationContextEnum
    }) => {

    const [useNewLoginData, setUseNewLoginData] = useState<boolean>(false);
    const [showTimer, setShowTimer] = useState<boolean>(authenticationModel.StoreUserAuthentication);
    const { t } = useTranslation(["general", "altinn"]);

    const hasUserAuthentication = useMemo(() => {
        return authenticationModel.StoreUserAuthentication &&
            authenticationModel.UserAuthentication !== null &&
            !userAuthentication.StoredCredentialsExpired &&
            !useNewSettings &&
            !useNewLoginData;
    }, [authenticationModel, userAuthentication, useNewLoginData])

    useEffect(() => {
        if (userAuthentication.StoredCredentialsExpired) {
            setUserAuthentication({ ...DefaultAltinnUserAuthentication, StoredCredentialsExpired: true } as IUserAuthentication);
            setShowTimer(false);
        }
    }, [userAuthentication.StoredCredentialsExpired])

    useEffect(() => {
        if(useNewLoginData){
            setUserAuthentication({...userAuthentication, Pincode: ""});
        }
        else{
            if(authenticationModel.UserAuthentication)
                setUserAuthentication((prevUserAuthentication: IUserAuthentication) => ({ 
                    ...prevUserAuthentication,
                    Pincode: authenticationModel.UserAuthentication.Pincode
                 } as IUserAuthentication ))
        }
    }, [useNewLoginData])

    useEffect(() => {
        if(useNewSettings)
            setUseNewLoginData(true);
        else
            setUseNewLoginData(false);
    }, [useNewSettings])

    return (
        <div className="altinn-user-authentication">
            {

                <div className={`${!hasUserAuthentication && 'd-none'}`} data-testid="saved-user-authentication">
                    <div className="row">
                        <div className="col">
                            <span>{t("SocialSecurityIdOrUsername", { ns: "altinn" })}</span>
                        </div>
                        <div className="col" data-testid="social-security-id-div">
                            {userAuthentication.SocialSecurityId}
                        </div>
                    </div>
                    <button className="btn mt-32"
                        data-testid="change-login-information-button"
                        onClick={() => setUseNewLoginData(!useNewLoginData)}>
                        {t("ChangeLoginInformation")}
                    </button>
                    {userAuthentication.ExpirationInSeconds !== undefined &&
                        <div className={`${!showTimer && 'd-none'}`}>
                            <AltinnCredentialsTimer initialSeconds={userAuthentication.ExpirationInSeconds} setExpired={(value: boolean) => { setUserAuthentication({ ...userAuthentication, StoredCredentialsExpired: value }) }} />
                        </div>
                    }
                </div>
            }
            {
                !hasUserAuthentication &&
                <UserCredentialsFormContext.Provider value=
                    {{
                        ids: ids,
                        source: source,
                        companyId: authenticationModel.CompanyId,
                        authenticationType: authenticationModel.AuthenticationType,
                        systemAuthentication,
                        userAuthentication,
                        useNewSettings,
                        setUserAuthentication
                    }}>
                    <AltinnUserAuthenticationForm />
                </UserCredentialsFormContext.Provider>
            }
        </div>
    );
}
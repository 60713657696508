import { useEffect, useState } from "react"
import altinnTaxCardService from "../../services/altinnTaxCardService";
import { IAltinnTaxCardData } from "../../interfaces/TaxCard";
import { useTranslation } from "react-i18next";
import { Alert, ComponentLoadingSpinner } from "../../components";
import { getEditSettingsUrlAltinnForTaxCard, triggerWootric } from "../../utils";
import { useLocation, useOutletContext } from "react-router-dom";
import { IContext, IDefaultResponseModel } from "../../interfaces";
import { Panel, PanelBody, PanelFooter } from "@vismaux/react-nc4";
import { ActionButton } from "../../components/ActionButton";
import { ResponseTypeEnum } from "../../utils/constants";
import { useAlert } from "../../hooks/AlertHook";
import { TaxCardRowContentValue } from "./TaxCardRowContentValue";
import { DialogCloseButton } from "../../components/DialogCloseButton";

const defaultValue = "n/a";

export const AltinnTaxCardAuthentication = ({ referenceId }: { referenceId: string }) => {
    const [data, setData] = useState<IAltinnTaxCardData>();
    const { t } = useTranslation(["general", "altinn"]);
    const currentCompany = useOutletContext<IContext>();
    const { search } = useLocation();
    const [alert, showAlert, clearAlert] = useAlert();

    useEffect(() => {
        if (referenceId)
            fetchData();
    }, [referenceId]);

    const fetchData = () => {
        altinnTaxCardService.getTaxCardData(referenceId)
            .then((data: IAltinnTaxCardData) => {
                setData(data);
            })
    }

    const handleSend = () => {
        return altinnTaxCardService.sendTaxCard(referenceId);
    }

    const sendTaxCardCallback = (data: IDefaultResponseModel) => {
        triggerWootric('report_send');
        
        if (data.ResponseType === ResponseTypeEnum.Danger)
            showAlert({ type: ResponseTypeEnum.Danger, message: data.Message, title: data.Title, sticky: true });
        else
            clearAlert();

        fetchData();
    }

    const businessLoginMissing = (data: IAltinnTaxCardData) => {
        return data.NeedsAuthentication && data.ShouldUseBusinessAuthentication && !data.IsBusinessAuthenticationSet;
    }

    const isBusinessLogin = (data: IAltinnTaxCardData) => {
        return data.ShouldUseBusinessAuthentication && data.IsBusinessAuthenticationSet;
    }

    return (
        <>
            <div className="row ar-tax-card-details-container">
                {!data ? <ComponentLoadingSpinner /> :
                    <>
                        <div className="col">
                            <TaxCardRowContentValue title={t("OrganizationName")} value={data ? data.OrganizationName : defaultValue} />
                            <TaxCardRowContentValue title={t("OrganizationNumber")} value={data ? data.OrganizationNumber : defaultValue} />
                        </div>
                        <div className="col">
                            <Alert alert={{
                                type: data.StatusDetails.Category,
                                title: data.StatusDetails.Title,
                                message: data.StatusDetails.Message && <span className="d-block mt-24">{data.StatusDetails.Message}</span>,
                                sticky: true,
                            }} />
                            {businessLoginMissing(data) &&
                                <>
                                    <Alert alert={{
                                        type: ResponseTypeEnum.Danger,
                                        title: t("MissingBusinessLogin", { ns: "altinn" }),
                                        message: '',
                                        sticky: true,
                                    }} />
                                </>
                            }
                            {!businessLoginMissing(data) &&
                                <Panel className="rounded-10 tax-card-authentication-panel mb-0">
                                    {data.NeedsAuthentication &&
                                        <PanelBody>
                                            {
                                                data.ShouldUseBusinessAuthentication && data.IsBusinessAuthenticationSet &&
                                                <>
                                                    <p>{t("UseBusinessCertificateForSendTaxCard", { ns: "altinn" })}</p>
                                                </>
                                            }
                                            {
                                                !data.HasSettings && !data.ShouldUseBusinessAuthentication &&
                                                <>
                                                    <p>{t("MissingSettingsText", { ns: "altinn" })}</p>
                                                    <a className="btn btn-primary" href={getEditSettingsUrlAltinnForTaxCard(referenceId, data.CompanyId, currentCompany.Id, search)}>{t("EditSettings")}</a>
                                                </>
                                            }
                                            {
                                                data.HasSettings && !data.ShouldUseBusinessAuthentication &&
                                                <div className="altinn-authentification">
                                                    <div className="row">
                                                        <div className="col">
                                                            <span>{t("ComputerSystemID", { ns: "altinn" })}</span>
                                                        </div>
                                                        <div className="col" data-testid="computer-system-id">
                                                            {data.SystemId}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {alert && <div className="mt-8">{alert}</div>}
                                        </PanelBody>
                                    }
                                    <PanelFooter>
                                        {data.NeedsAuthentication && (data.HasSettings || isBusinessLogin(data)) && <ActionButton onclick={handleSend}
                                            payload={referenceId}
                                            text={t("Send")}
                                            iconClassName="vismaicon vismaicon-sm ar-send"
                                            buttonClassName="btn-primary"
                                            callback={sendTaxCardCallback}
                                            dataTestId="send-button" />
                                        }
                                        {data.ShowCheckStatusButton &&
                                            <ActionButton onclick={fetchData}
                                                text={t("CheckStatus")}
                                                buttonClassName="btn-primary"
                                                iconClassName="vismaicon vismaicon-sm vismaicon-refresh"
                                                dataTestId="check-status" />
                                        }
                                    </PanelFooter>
                                </Panel>
                            }
                            <div className="mt-24">
                                <DialogCloseButton action={"closed"} dialog="altinntaxcard/authenticaterequest" />
                            </div>
                        </div>
                    </>}
            </div>
        </>)
}

import '../../../styles/settings.css';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useOutletContext } from "react-router-dom";
import { DropdownButton } from "../../../components";
import { IAltinnSettingsRequest, IAltinnSettingsResponse, IAltinnCredentials, IContext, ISelectModel, IAltinnCompanyCredentials } from "../../../interfaces";
import { AltinnSettingsInfo, ViewAllCompaniesModal, AltinnSettingsComputerSystemId } from ".";
import { useAlert } from '../../../hooks/AlertHook';
import { AltinnSettingsContext } from '../../../contexts/AltinnSettingsContext';
import { ResponseTypeEnum } from '../../../utils/constants';
import { displayGoBackButton, postMessageToParent, redirectToOriginUrl, sendMessageToParentFromSettingsPage, triggerWootric } from '../../../utils';
import altinnSettingService from '../../../services/altinnSettingService';
import { CountrySelection } from '../CountrySelection';
import { useClickInputHook } from '../../../hooks/ClickInputHook';
import { sendEvent } from "../../../hooks/GoogleAnalyticsHook";

export const AltinnSettingsPage = () => {

    const currentCompany = useOutletContext<IContext>();
    const { t } = useTranslation(["altinn", "general"]);
    // initial data
    const [altinnSettingsData, setAltinnSettingsData] = useState<IAltinnSettingsResponse>();
    const [computerSystemIds, setComputerSystemIds] = useState<IAltinnCompanyCredentials[]>([]);

    // form data
    const [defaultReportStep, setDefaultReportStep] = useState<string>("");
    const [computerSystemId, setComputerSystemId] = useState<IAltinnCredentials>({} as IAltinnCredentials);
    const [password, setPassword] = useState<string>("");

    const [showModal, setShowModal] = useState<boolean>(false);
    const [alert, showAlert, clearAlert] = useAlert();
    useClickInputHook(clearAlert);

    const { search } = useLocation();

    useEffect(() => {
        sendEvent("settings_norway", "Open norwegian settings page.");
        fetchData();
    }, [currentCompany])

    const fetchData = () => {
        setPassword("");

        return altinnSettingService.getCredentials().then((credentials: IAltinnCompanyCredentials[]) => {
            setComputerSystemIds(credentials);

            altinnSettingService.getSettings(currentCompany.Id).then((data: IAltinnSettingsResponse) => {
                setAltinnSettingsData(data);
                setDefaultReportStep(data.ReportStep ? data.ReportStep : "");

                const currentCredentials = credentials.find(c => c.Id === data.ActiveAltinnCredentials?.Id);
                setComputerSystemIdWrapper(currentCredentials);
            })
        })
    }

    const setComputerSystemIdWrapper = async (currentCredentials: ISelectModel | undefined) => {
        if (currentCredentials && currentCredentials.Id) {
            altinnSettingService.getNumberOfCompaniesUsingCredentials(currentCredentials.Id).then((number) => {
                setComputerSystemId({ Id: currentCredentials.Id, Text: currentCredentials.Text, CompaniesAssociated: number })
            })
        }
        else
            setComputerSystemId({ Id: "", Text: currentCredentials ? currentCredentials.Text : "", CompaniesAssociated: 0 });
    }

    const handleSave = () => {
        const newSettings: IAltinnSettingsRequest = {
            ReportStep: defaultReportStep,
            ComputerSystemId: computerSystemId.Text,
            Password: password,
            CompanyIds: [currentCompany.Id]
        }

        clearAlert();

        altinnSettingService.updateSettings(newSettings).then(() => {
            showAlert({ message: t("SettingsUpdatedSuccessfully", { ns: "general" }), type: ResponseTypeEnum.Success });
            if (sendMessageToParentFromSettingsPage(search))
                postMessageToParent("settings/altinn", "sent");

            fetchData().then(() => {
                redirectToOriginUrl(search);
            })
        }).catch((error) => {
            const message = error?.data;
            showAlert({ title: t("Error", { ns: "general" }), message: message ?? t("SomethingWentWrongTitle", { ns: "general" }), type: ResponseTypeEnum.Danger });
        });

        triggerWootric('settings_save');
    }

    const handleCancel = () => {
        if (altinnSettingsData) {
            setDefaultReportStep(altinnSettingsData.ReportStep);
            setComputerSystemIdWrapper(altinnSettingsData.ActiveAltinnCredentials ? computerSystemIds.find(c => c.Id === altinnSettingsData.ActiveAltinnCredentials?.Id) : undefined);
        } else {
            setDefaultReportStep("");
            setComputerSystemIdWrapper(undefined);
        }

        if (sendMessageToParentFromSettingsPage(search))
            postMessageToParent("settings/altinn", "cancelled");

        setPassword("");
    }

    const handleDeleteComputerSystemId = async (systemId: string) => {
        return altinnSettingService.deleteCredentials(systemId)
            .then(() => {
                fetchData();
            });
    }

    const disableButtons = () => {
        const sameAsInitial = altinnSettingsData?.ReportStep === defaultReportStep && altinnSettingsData.ActiveAltinnCredentials?.Id === computerSystemId.Id && !password;
        const mandatoryFieldsNotFilled = !computerSystemId.Id && (!password || !computerSystemId.Text) || !defaultReportStep;

        return sameAsInitial || mandatoryFieldsNotFilled;
    }

    return (
        <AltinnSettingsContext.Provider value={{ password, computerSystemId, setDefaultReportStep, setComputerSystemId: setComputerSystemIdWrapper, setPassword, handleDeleteComputerSystemId, fetchData }}>
            <div className="ar-settings-container">
                <div className="panel ar-panel ar-settings-left">
                    <div className="d-flex justify-content-between">
                        <div>
                            <h1 className="mb-0">{t("Settings", { ns: "general" })}</h1>
                            {displayGoBackButton(search) && <button type="button" className="btn mt-32 mb-16" data-testid="return-button" onClick={() => redirectToOriginUrl(search)}>{t("GoBack", { ns: 'general' })}</button>}
                        </div>
                        <div className="altinn-logo" />
                    </div>
                    <h3 className="mt-16 mb-32">{t("SettingsForCompany", { ns: "general" })} <b>{currentCompany.Name}.</b></h3>
                    {altinnSettingsData &&
                        <>
                            <div className="w-256">
                                <h2 className="mb-16">{t("DefaultReportStep")}</h2>
                                <DropdownButton
                                    className='ar-settings-dropdown'
                                    options={altinnSettingsData.ReportSteps.map(p => { return { text: p.Text, key: p.Id } })}
                                    onChange={(value) => setDefaultReportStep(value)}
                                    value={defaultReportStep} />
                            </div>
                            <div className="mt-24">
                                <AltinnSettingsComputerSystemId
                                    computerSystemIds={computerSystemIds}
                                    currentComputerSystemId={altinnSettingsData.ActiveAltinnCredentials}
                                    totalCompanies={altinnSettingsData.TotalCompanies}
                                />
                            </div>
                            <div className="mt-24">
                                <h2>{t("AllCompanies", { ns: "general" })} </h2>
                                <button className="btn mt-24" disabled={!computerSystemIds.length} data-testid="view-all-button" onClick={() => setShowModal((previous: boolean) => !previous)}>{t("ViewAll", { ns: "general" })}..</button>
                            </div>
                            {showModal &&
                                <ViewAllCompaniesModal
                                    computerSystemIds={computerSystemIds}
                                    reportSteps={altinnSettingsData.ReportSteps}
                                    showAlert={showAlert}
                                    onClose={() => { setShowModal(false); triggerWootric('settings_modal'); }} />
                            }

                            <div className="ar-alert-container">
                                {alert}
                            </div>

                            <div className="d-flex justify-content-end mt-128">
                                <button type="button" className="btn btn-primary" disabled={disableButtons()} data-testid="save-button" onClick={() => handleSave()}>{t("Save", { ns: 'general' })}</button>
                                <button type="button" className="btn" data-testid="cancel-button" onClick={() => handleCancel()}>{t("Cancel", { ns: 'general' })}</button>
                            </div>
                        </>
                    }
                </div>
                <div className="ar-settings-right">
                    <CountrySelection />
                    {altinnSettingsData && <AltinnSettingsInfo isBusinessAuthenticationSet={altinnSettingsData.IsBusinessAuthenticationSet} 
                        isBusinessAuthenticationType={altinnSettingsData.IsBusinessAuthenticationType}/>}
                </div>
            </div>
        </AltinnSettingsContext.Provider>
    )
}
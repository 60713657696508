import { toLower } from "lodash";
import { useContext, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { CertificateActiveButton, ComponentLoadingSpinner, ExpandableSection } from "../../../../components";
import { ConfirmModal } from "../../../../components/ConfirmModal";
import { DigipoortSettingsContext } from "../../../../contexts/DigipoortSettingsContext";
import { IDefaultResponseModel, IDigipoortVismaCertificateProps } from "../../../../interfaces";
import digipoortService from "../../../../services/digipoortService";
import { postMessageToParent, redirectToOriginUrl, sendMessageToParentFromSettingsPage, triggerWootric } from "../../../../utils";
import { ResponseTypeEnum } from "../../../../utils/constants";
import { ViewAllCompanyCertificatesModal } from "../../ViewAllCompanyCertificatesModal";

export const DigipoortVismaCertificate = ({ vismaCertificateActivated, totalCompanies, companiesUsingVismaCorporateCertificate }: IDigipoortVismaCertificateProps) => {
    const { t } = useTranslation(["general", "digipoort"]);
    const { showAlert, refreshData, clearAlert } = useContext(DigipoortSettingsContext);
    const { search } = useLocation();

    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [showViewAllModal, setShowViewAllModal] = useState<boolean>(false);

    const action = vismaCertificateActivated ? t("Deactivate") : t("Activate");

    const handleConfirmAction = () => {
        setShowLoading(true);
        setShowConfirmModal(false);
        var value = !vismaCertificateActivated;
        digipoortService.setVismaCertificate(value)
            .then((data: IDefaultResponseModel) => {
                setShowLoading(false);
                showAlert({ message: data.Message, type: data.ResponseType, title: data.Title });

                if (data.ResponseType === ResponseTypeEnum.Success) {
                    if (sendMessageToParentFromSettingsPage(search))
                        postMessageToParent("settings/digipoort", "sent");
                    refreshData();
                    redirectToOriginUrl(search);
                }

                if (value) {
                    triggerWootric('settings_save');
                }
            })
    }


    return (
        <>
            <p className="mb-24">{t("DigipoortVismaCertificate", { ns: "digipoort" })} {t("DigipoortVismaCertificate2", { ns: "digipoort" })}</p>
            <ExpandableSection header={<ConditionsHeader conditionsTitle={t("ConditionsForVismaCertificateTitle")} />}>
                <p className="mt-16">{t("ConditionsForVismaCertificateDescription", { ns: "digipoort" })}</p>
            </ExpandableSection>
            {showLoading && <ComponentLoadingSpinner />}
            {!showLoading &&
                <>
                    {
                        vismaCertificateActivated &&
                        <div className="d-flex align-items-center mt-32">
                            <CertificateActiveButton title={t("CertificateActive")} />
                            <div data-testid="credentials-statistics">
                                <Trans
                                    i18nKey="CredentialsStatistics"
                                    ns={"general"}
                                    values={{ companies: companiesUsingVismaCorporateCertificate, totalCompanies: totalCompanies }}
                                    components={{ bold: <strong /> }}
                                />
                            </div>
                        </div>
                    }
                    <h2 className="mt-24 mb-32">{t("CertificateOptions")}</h2>
                    <button className="btn"
                        data-testid="change-certificate-state-button"
                        onClick={() => setShowConfirmModal(true)}>{action}</button>
                    <button className="btn ml-32"
                        data-testid="view-all-button"
                        disabled={!vismaCertificateActivated}
                        onClick={() => setShowViewAllModal(true)}>{t("ViewAll")}..</button>
                </>
            }
            {
                showConfirmModal &&
                <ConfirmModal title={`${t(action, { ns: "general" })} ${toLower(t("Certificate", { ns: "general" }))}`}
                    description={t(vismaCertificateActivated ? "ConfirmCertificateDeactivateMessage" : "ConfirmCertificateActivateMessage")}
                    onSuccess={handleConfirmAction}
                    onClose={() => setShowConfirmModal(false)} />
            }

            {showViewAllModal &&
                <ViewAllCompanyCertificatesModal
                    onClose={() => { setShowViewAllModal(false); triggerWootric('settings_modal'); }}
                    currentCertificate={t("Visma")}
                    fetchTableData={digipoortService.getSettingsForAvailableCompanies}
                    copyCertificateForCompanies={digipoortService.copySettingsForCompanies}
                    showAlert={showAlert}
                    refreshData={refreshData} />
            }
        </>
    )
}

const ConditionsHeader = (props: any) => {
    return (<div className="d-flex align-items-center">
        <h3 className="mb-0">{props.conditionsTitle}</h3>
        <span className="caret ml-16"></span>
    </div>)
}
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ComputerSystemFromContext } from "../../contexts/AltinnSettingsContext";
import { IAltinnAuthenticationModel, IAuthenticationData, ISystemAuthentication, IUserAuthentication } from "../../interfaces";
import { AddComputerSystemIdForm } from "../../pages/settings";
import { AltinnUserAuthentication } from "./AltinnUserAuthentication";
import altinnAuthenticationService from "../../services/altinnAuthenticationService";
import { AltinnUserAuthenticationContextEnum, DefaultAltinnUserAuthentication } from "../../utils/constants";

export const AltinnAuthentication = ({ companyId, ids, reportType, source, settingsUrl, setAuthenticationData, useUserAuthentication, authenticationData }:
    {
        companyId: number,
        ids: string[],
        reportType: string,
        source: AltinnUserAuthenticationContextEnum,
        settingsUrl: string,
        setAuthenticationData: (data: IAuthenticationData) => any,
        authenticationData?: IAuthenticationData,
        useUserAuthentication?: boolean
    }) => {
    const { t } = useTranslation(["general", "altinn"]);
    //initial data
    const [authenticationModel, setAuthenticationModel] = useState<IAltinnAuthenticationModel>(null as unknown as IAltinnAuthenticationModel);
    //use for new settings data
    const [systemAuthentication, setSystemAuthentication] = useState<ISystemAuthentication>({ UserName: "", Password: "" } as ISystemAuthentication);
    //use for authentication data
    const [userAuthentication, setUserAuthentication] = useState<IUserAuthentication>(DefaultAltinnUserAuthentication);

    const [useNewSystemSettings, setUseNewSystemSettings] = useState<boolean>(false);

    const loaded = useRef<boolean>(false);

    useEffect(() => {
        if (!authenticationData) {
            loaded.current = false;

            altinnAuthenticationService
                .getAuthenticationModel(companyId, reportType)
                .then((data: IAltinnAuthenticationModel) => {
                    setAuthenticationModel(data);
                    setUserAuthentication({
                        ...data.UserAuthentication,
                        StoreUserAuthentication: data.StoreUserAuthentication,
                        PasswordHasChanged: false,
                    })

                    loaded.current = true;
                })
        } else {
            setSystemAuthentication(authenticationData.authenticationData.System);
            setUserAuthentication(authenticationData.authenticationData.User);
            setUseNewSystemSettings(!authenticationData.authenticationData.UseExistingSystemAuthentication);
        }
    }, [authenticationData])

    useEffect(() => {
        if (!loaded.current)
            return;

        var useNewUserAuthentication = userAuthentication.PasswordHasChanged || (authenticationModel.StoreUserAuthentication == true &&
            (authenticationModel.UserAuthentication.Pincode != userAuthentication.Pincode || authenticationModel.UserAuthentication.SocialSecurityId != userAuthentication.SocialSecurityId));

        const authenticationData: IAuthenticationData = {
            disabled: disableSend(),
            authenticationData: {
                UseExistingSystemAuthentication: !useNewSystemSettings,
                UseExistingUserAuthentication: !useNewUserAuthentication,
                User: userAuthentication,
                System: systemAuthentication,
                StoreUserAuthentication: userAuthentication.StoreUserAuthentication,
                ShouldUseBusinessAuthentication: authenticationModel.ShouldUseBusinessAuthentication,
                IsBusinessAuthenticationSet: authenticationModel.IsBusinessAuthenticationSet
            }
        };

        setAuthenticationData(authenticationData);
    }, [useNewSystemSettings, systemAuthentication, userAuthentication])

    const disableSend = () => {
        if (authenticationModel.ShouldUseBusinessAuthentication && !authenticationModel.IsBusinessAuthenticationSet) {
            return true;
        }

        if (authenticationModel.IsBusinessAuthenticationSet)
            return false;

        if (!authenticationModel.HasSystemCredentials)
            return true;

        if (useNewSystemSettings && (!systemAuthentication.Password || !systemAuthentication.UserName))
            return true;

        if (useUserAuthentication && (!userAuthentication.SocialSecurityId || !userAuthentication.Password || !userAuthentication.Pincode || userAuthentication.StoredCredentialsExpired === true))
            return true;

        return false;
    }

    const handleUseNewSystemSettings = () => {
        if (useNewSystemSettings) {
            setSystemAuthentication({} as ISystemAuthentication);
        }

        setUseNewSystemSettings(!useNewSystemSettings);
    }

    const displayBusinessCertificate = () => {
        return authenticationModel.ShouldUseBusinessAuthentication && authenticationModel.IsBusinessAuthenticationSet && source === AltinnUserAuthenticationContextEnum.DownloadAltinnTaxCard;
    }

    return (
        <>
            {authenticationModel &&
                <>
                    {displayBusinessCertificate() && <p>{t("UseBusinessCertificateForTaxCardText", { ns: "altinn" })}</p>}

                    {!authenticationModel.ShouldUseBusinessAuthentication && !displayBusinessCertificate() && <>
                        {!authenticationModel.HasSystemCredentials &&
                            <>
                                <p>{t("MissingSettingsText", { ns: "altinn" })}</p>
                                <a className="btn btn-primary" href={settingsUrl}>{t("EditSettings")}</a>
                            </>
                        }

                        {!authenticationModel.ShouldUseBusinessAuthentication && !displayBusinessCertificate() && authenticationModel.HasSystemCredentials &&
                            <div className="altinn-authentification">
                                <div className="system-authentification">
                                    <div className="row">
                                        <div className="col">
                                            <span>{t("ComputerSystemID", { ns: "altinn" })}</span>
                                        </div>
                                        <div className="col" data-testid="computer-system-id">
                                            {authenticationModel.SystemAuthentication.UserName}
                                        </div>
                                    </div>
                                </div>

                                <p className="mt-12 mb-0">{t("UseDifferentCredentialsMessage", { ns: "altinn" })}</p>

                                <button data-testid="use-new-settings-button" className="btn mt-8 mb-0" onClick={handleUseNewSystemSettings}>{useNewSystemSettings ? t("UseSameSettings") : t("UseDifferentSettings")}</button>

                                {useNewSystemSettings &&
                                    <ComputerSystemFromContext.Provider value={{
                                        setComputerSystemId: (value: string) => setSystemAuthentication({ ...systemAuthentication, UserName: value }),
                                        setPassword: (value: string) => setSystemAuthentication({ ...systemAuthentication, Password: value }),
                                        computerSystemId: systemAuthentication.UserName,
                                        password: systemAuthentication.Password
                                    }}>
                                        <AddComputerSystemIdForm />
                                    </ComputerSystemFromContext.Provider>
                                }

                                {useUserAuthentication &&
                                    <AltinnUserAuthentication
                                        source={source}
                                        ids={ids}
                                        useNewSettings={useNewSystemSettings}
                                        userAuthentication={userAuthentication}
                                        systemAuthentication={systemAuthentication}
                                        authenticationModel={authenticationModel}
                                        setUserAuthentication={setUserAuthentication}
                                    />
                                }
                            </div>
                        }
                    </>}
                </>
            }
        </>
    )
}
import { Alert, Checkbox } from ".."
import { FormEvent, useContext, useRef, useState, KeyboardEvent } from "react"
import { useTranslation } from "react-i18next"
import { FormInput } from ".."
import { UserCredentialsFormContext } from "../../contexts/AltinnSettingsContext"
import { IAlert, IRequestOtpData, IDefaultResponseModel, IAltinnAuthenticationInfo } from "../../interfaces"
import altinnAuthenticationService from "../../services/altinnAuthenticationService"
import { ResponseTypeEnum, DefaultAltinnUserAuthentication } from "../../utils/constants"
import { handleKeyDown } from "../../utils"

export const AltinnUserAuthenticationForm = () => {
    const { t } = useTranslation(["general", "altinn"])
    const { ids,
        companyId,
        authenticationType,
        systemAuthentication,
        userAuthentication,
        source,
        useNewSettings,
        setUserAuthentication } = useContext(UserCredentialsFormContext);

    const [alert, setAlert] = useState<IAlert | undefined>();
    const pinCodeRef = useRef<HTMLInputElement>(null);

    const handleRequestSMSPinCode = (event: FormEvent) => {
        event.preventDefault();

        requestOtpPassoword();
        logData();
    }

    const requestOtpPassoword = () => {
        var data: IRequestOtpData = {
            CompanyId: companyId,
            SystemAuthentication: systemAuthentication,
            AuthenticationType: authenticationType,
            SocialSecurityId: userAuthentication.SocialSecurityId,
            UserPassword: userAuthentication.Password,
            UserPasswordChanged: userAuthentication.PasswordHasChanged,
            StoredCredentialsExpired: userAuthentication.StoredCredentialsExpired
        };

        altinnAuthenticationService
            .requestOtp(data)
            .then((response: IDefaultResponseModel) => {
                if (response.ResponseType === ResponseTypeEnum.Success) {
                    pinCodeRef.current?.focus();
                }
                else
                    clearData();

                setAlert({ type: response.ResponseType, message: response.Message, sticky: true })
            })
    }

    const logData = () => {
        var data: IAltinnAuthenticationInfo = {
            CompanyId: companyId,
            SystemAuthentication: systemAuthentication,
            AuthenticationType: authenticationType,
            Ids: ids,
            Source: source,
            UseDifferentCredentials: useNewSettings
        };
        altinnAuthenticationService.logData(data);
    }

    const clearData = () => {
        setUserAuthentication(DefaultAltinnUserAuthentication);
    }

    const handleKeyDownForm = (event: KeyboardEvent<HTMLFormElement>) => {
        handleKeyDown(event, "Enter", () => { event.preventDefault(); });
    };

    const handleKeyDownRequestSMSPinCode = (event: KeyboardEvent<HTMLButtonElement>) => {
        handleKeyDown(event, "Enter", () => { event.preventDefault(); handleRequestSMSPinCode(event)});
    };

    const handleKeyDownCheckbox = (event : KeyboardEvent<HTMLInputElement>) => {
        handleKeyDown(event, "Enter", () => { event.preventDefault(); setUserAuthentication({ ...userAuthentication, StoreUserAuthentication: !userAuthentication.StoreUserAuthentication })});
    }

    return (<>
        <p className="mb-8">{t("InputDataMessage", { ns: "altinn" })}</p>
        <form className="altinn-user-authentication-form" data-testid="altinn-user-authentication-form" onKeyDown={handleKeyDownForm}>
            <div className="form-group required">
                <label className="d-block">{t("SocialSecurityIdOrUsername", { ns: "altinn" })}</label>
                <FormInput value={userAuthentication.SocialSecurityId ? userAuthentication.SocialSecurityId : ""}
                    onChange={(value: string) => { setUserAuthentication({ ...userAuthentication, SocialSecurityId: value }) }}
                    autocomplete="off"
                    label={t("SocialSecurityIdOrUsername", { ns: "altinn" })} />
            </div>
            <div className="form-group required">
                <label className="d-block">{t("Password")}</label>
                <FormInput value={userAuthentication.Password ? userAuthentication.Password : ""}
                    autocomplete="new-password"
                    onChange={(value: string) => { setUserAuthentication({ ...userAuthentication, Password: value, PasswordHasChanged: true }) }}
                    label={t("Password")} isPassword={true} />
            </div>
            <button className="btn btn-primary mt-16"
                onClick={handleRequestSMSPinCode}
                onKeyDown={handleKeyDownRequestSMSPinCode}
                data-testid="request-sms-pin-code-button">
                {t("RequestSMSPinCode", { ns: "altinn" })}
            </button>
            {alert && <div className="ar-fetch-response-alert"><Alert alert={alert} /></div>}
            <div className={`form-group required`}>
                <label className="d-block">{t("PinCodeReceived", { ns: "altinn" })}</label>
                <FormInput value={userAuthentication.Pincode ? userAuthentication.Pincode : ""}
                    inputRef={pinCodeRef}
                    onChange={(value: string) => { setUserAuthentication({ ...userAuthentication, Pincode: value }) }}
                    autocomplete="off"
                    label={t("PinCodeReceived", { ns: "altinn" })} />
            </div>
            <div className="form-group required">
                {!useNewSettings && 
                    <Checkbox isChecked={userAuthentication.StoreUserAuthentication}
                        onChange={(value: boolean) => { setUserAuthentication({ ...userAuthentication, StoreUserAuthentication: value }) }}
                        onKeyDown={handleKeyDownCheckbox}
                        label={t("RememberLogin", { ns: "altinn" })} />
                }
            </div>
        </form>
    </>)
}
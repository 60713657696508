import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useOutletContext } from "react-router-dom";
import { DropdownButton } from "../../../components";
import { ComputerSystemFromContext } from "../../../contexts/AltinnSettingsContext";
import { ReportExpandedRowContext } from "../../../contexts/ReportExpandedRowContext";
import { IAltinnAuthenticationModel, IContext, ISystemAuthentication, IUserAuthentication } from "../../../interfaces";
import altinnService from "../../../services/altinnService";
import { getEditSettingsUrlForReport } from "../../../utils";
import { AltinnUserAuthenticationContextEnum, DefaultAltinnUserAuthentication, ReportStepEnum } from "../../../utils/constants";
import { AddComputerSystemIdForm } from "../../settings";
import { AltinnUserAuthentication } from "../../../components/authentication/AltinnUserAuthentication";

export const Altinn = ({ reportId }: { reportId: string }) => {
    //initial data
    const [authenticationModel, setAuthenticationModel] = useState<IAltinnAuthenticationModel>({} as IAltinnAuthenticationModel);
    const currentCompany = useOutletContext<IContext>();
    const { setSendButtonProps } = useContext(ReportExpandedRowContext);
    const { t } = useTranslation(["general", "altinn"]);
    const { search } = useLocation();

    const [useNewSettings, setUseNewSettings] = useState<boolean>(false);

    //use new settings form data
    const [systemAuthentication, setSystemAuthentication] = useState<ISystemAuthentication>({} as ISystemAuthentication);
    const [reportStep, setReportStep] = useState<string>();

    //use for user authentication form data
    const [userAuthentication, setUserAuthentication] = useState<IUserAuthentication>(DefaultAltinnUserAuthentication);

    const getAuthenticationData = useCallback(() => {
        var authenticationData = {
            CompanyId: authenticationModel.CompanyId,
            AuthenticationType: reportStep,
            SocialSecurityId: "",
            Password: "",
            PasswordChanged: false,
            StoreUserAuthentication: false,
            StoredCredentialsExpired: false,
            OtpPassword: "",
            SystemAuthentication: {},
            ShouldUseBusinessAuthentication: authenticationModel.ShouldUseBusinessAuthentication,
            IsBusinessAuthenticationSet: authenticationModel.IsBusinessAuthenticationSet
        };

        if (useNewSettings) {
            authenticationData.SystemAuthentication = systemAuthentication;
        }

        if (reportStep === ReportStepEnum.CompletedAndSigned) {
            authenticationData.StoreUserAuthentication = userAuthentication.StoreUserAuthentication;
            authenticationData.SocialSecurityId = userAuthentication.SocialSecurityId;
            authenticationData.Password = userAuthentication.Password;
            authenticationData.PasswordChanged = userAuthentication.PasswordHasChanged;
            authenticationData.OtpPassword = userAuthentication.Pincode;
            authenticationData.StoredCredentialsExpired = userAuthentication.StoredCredentialsExpired;
        }

        return authenticationData;
    }, [authenticationModel, systemAuthentication, useNewSettings, userAuthentication, reportStep])

    const disableSend = useMemo(() => {
        if (authenticationModel.ShouldUseBusinessAuthentication && !authenticationModel.IsBusinessAuthenticationSet)
            return true;

        if (authenticationModel.IsBusinessAuthenticationSet)
            return false;

        if (!authenticationModel.HasSystemCredentials)
            return true;

        if (useNewSettings === true && (!systemAuthentication.Password || !systemAuthentication.UserName))
            return true;

        if (reportStep === ReportStepEnum.CompletedAndSigned && (!userAuthentication.SocialSecurityId || !userAuthentication.Password || !userAuthentication.Pincode || userAuthentication.StoredCredentialsExpired === true))
            return true;

        return false;
    }, [authenticationModel, useNewSettings, systemAuthentication, reportStep, userAuthentication])

    useEffect(() => {
        altinnService
            .getReportAuthenticationModel(reportId)
            .then((data: IAltinnAuthenticationModel) => {
                setAuthenticationModel(data);
                setReportStep(data.AuthenticationType);
                if (data.StoreUserAuthentication)
                    setUserAuthentication({
                        ...userAuthentication,
                        ...data.UserAuthentication,
                        StoreUserAuthentication: data.StoreUserAuthentication,
                        PasswordHasChanged: false,
                    })
            })
    }, [])

    useEffect(() => {
        if (Object.keys(authenticationModel).length > 0)
            setSendButtonProps({
                text: t("Send"),
                disabled: disableSend,
                getAuthenticationData: getAuthenticationData
            })
    }, [authenticationModel, getAuthenticationData, disableSend])

    const handleUseNewSettings = () => {
        if (useNewSettings) {
            setReportStep(authenticationModel.AuthenticationType);
            setSystemAuthentication({} as ISystemAuthentication);
            var pinCodeWasCleared = (authenticationModel.UserAuthentication && authenticationModel.UserAuthentication.Pincode !== "") && userAuthentication.Pincode === "";
            if (authenticationModel.AuthenticationType === ReportStepEnum.CompletedAndSigned && pinCodeWasCleared)
                setUserAuthentication({ ...userAuthentication, Pincode: authenticationModel.UserAuthentication.Pincode })
        }
        else {
            if (authenticationModel.AuthenticationType === ReportStepEnum.CompletedAndSigned)
                setUserAuthentication({ ...userAuthentication, Pincode: "" })
        }

        setUseNewSettings(!useNewSettings);
    }

    return (
        <>
            {
                authenticationModel &&
                <>
                    {
                        authenticationModel.IsBusinessAuthenticationSet &&
                        <p>{t("UseBusinessCertificateForSendReport", { ns: "altinn" })}</p>
                    }
                    {
                        !authenticationModel.ShouldUseBusinessAuthentication && !authenticationModel.IsBusinessAuthenticationSet && !authenticationModel.HasSystemCredentials &&
                        <>
                            <p>{t("MissingSettingsText", { ns: "altinn" })}</p>
                            <a className="btn btn-primary" href={getEditSettingsUrlForReport(reportId, authenticationModel.CompanyId, currentCompany.Id, search)}>{t("EditSettings")}</a>
                        </>
                    }
                    {
                        !authenticationModel.ShouldUseBusinessAuthentication && !authenticationModel.IsBusinessAuthenticationSet && authenticationModel.HasSystemCredentials &&
                        <div className="altinn-authentification">
                            <div className="system-authentification">
                                <div className="row">
                                    <div className="col">
                                        <span>{t("ReportStep", { ns: "altinn" })}</span>
                                    </div>
                                    <div className="col" data-testid="authentication-type" title={authenticationModel.AuthenticationTypeDescriptionTooltip}>
                                        {authenticationModel.AuthenticationTypeDescription}
                                    </div>
                                </div>
                                <div className="row mt-16">
                                    <div className="col">
                                        <span>{t("ComputerSystemID", { ns: "altinn" })}</span>
                                    </div>
                                    <div className="col" data-testid="computer-system-id">
                                        {authenticationModel.SystemAuthentication.UserName}
                                    </div>
                                </div>
                            </div>
                            <p className="mt-32 mb-0">{t("UseDifferentCredentialsMessage", { ns: "altinn" })}</p>
                            <button data-testid="use-new-settings-button" className="btn mt-8" onClick={handleUseNewSettings}>{useNewSettings ? t("UseSameSettings") : t("UseDifferentSettings")}</button>
                            {
                                useNewSettings &&
                                <ComputerSystemFromContext.Provider value={{
                                    setComputerSystemId: (value: string) => setSystemAuthentication({ ...systemAuthentication, UserName: value }),
                                    setPassword: (value: string) => setSystemAuthentication({ ...systemAuthentication, Password: value }),
                                    computerSystemId: systemAuthentication.UserName,
                                    password: systemAuthentication.Password
                                }}>
                                    {
                                        authenticationModel.AllowReportStepChange &&
                                        <div className="form-group required w-352 mb-0" data-testid="report-step-div">
                                            <label className="d-block">{t("ReportStep", { ns: "altinn" })}</label>
                                            <DropdownButton
                                                options={authenticationModel.ReportSteps.map(p => { return { text: p.Text, key: p.Id } })}
                                                onChange={(value) => setReportStep(value)}
                                                value={reportStep} />
                                        </div>
                                    }
                                    <AddComputerSystemIdForm />
                                </ComputerSystemFromContext.Provider>
                            }
                            {
                                reportStep === ReportStepEnum.CompletedAndSigned &&
                                <AltinnUserAuthentication
                                    ids={[reportId]}
                                    source={AltinnUserAuthenticationContextEnum.SendReport}
                                    useNewSettings={useNewSettings}
                                    userAuthentication={userAuthentication}
                                    systemAuthentication={systemAuthentication}
                                    authenticationModel={authenticationModel}
                                    setUserAuthentication={setUserAuthentication} />
                            }
                        </div>
                    }
                </>
            }
        </>
    )
}
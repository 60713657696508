import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useOutletContext } from "react-router-dom";
import { ReportExpandedRowContext } from "../../../contexts/ReportExpandedRowContext";
import { IContext, IKatreAuthenticationModel } from "../../../interfaces";
import katreAuthentication from "../../../services/katreAuthentication";
import { getEditSettingsUrlForReport } from "../../../utils";

export const Katre = ({ reportId, missingSettingsText }: { reportId: string, missingSettingsText: string }) => {
    const [authenticationModel, setAuthenticationModel] = useState<IKatreAuthenticationModel>({} as IKatreAuthenticationModel);
    const { setSendButtonProps } = useContext(ReportExpandedRowContext);
    const { t } = useTranslation(["general", "katre"]);
    const currentCompany = useOutletContext<IContext>();
    const { search } = useLocation();

    const getAuthenticationData = () => {
        var authModel = {
            CompanyId: authenticationModel.CompanyId
        }

        return authModel;
    }

    useEffect(() => {
        katreAuthentication
            .getAuthenticationModel(reportId)
            .then((data: IKatreAuthenticationModel) => {
                setAuthenticationModel(data);
            })
    }, [])

    useEffect(() => {
        if (Object.keys(authenticationModel).length > 0)
            setSendButtonProps({
                text: t("Send"),
                disabled: !authenticationModel.HasCertificate,
                getAuthenticationData: getAuthenticationData
            })
    }, [authenticationModel])

    return (
        <>{!authenticationModel.HasCertificate &&
            <>
                <p>{t(missingSettingsText, { ns: "katre" })}</p>
                <a className="btn btn-primary" href={getEditSettingsUrlForReport(reportId, authenticationModel.CompanyId, currentCompany.Id, search)}>{t("EditSettings")}</a>
            </>
        }
            {authenticationModel.HasCertificate &&
                <>
                    <div className="row">
                        <div className="col col-4">
                            <p>{t("SerialNumber")}</p>
                        </div>
                        <div className="col col-8">
                            <b>{authenticationModel.SerialNumber}</b>
                        </div>
                    </div>
                    <div className="row mt-24">
                        <div className="col col-4">
                            <p>{t("CommonName")}</p>
                        </div>
                        <div className="col col-8">
                            <b>{authenticationModel.CommonName}</b>
                        </div>
                    </div>
                    <div className="row mt-24">
                        <div className="col col-4">
                            <p>{t("ValidUntil")}</p>
                        </div>
                        <div className="col col-8" data-testid="expiration-date">
                            <b>{authenticationModel.ExpirationDate}</b>
                        </div>
                    </div>
                </>
            }
        </>
    )
}